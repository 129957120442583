var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "page-titles p-3 border-bottom" }, [
      _c("h3", { staticClass: "text-themecolor" }, [
        _vm._v(_vm._s(_vm.trans("backup.backup")) + "\n            "),
        !_vm.backups.length
          ? _c("span", { staticClass: "card-subtitle" }, [
              _vm._v(_vm._s(_vm.trans("general.no_result_found")))
            ])
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "container-fluid p-0" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "card border-bottom" }, [
            _c(
              "div",
              { staticClass: "card-body p-4" },
              [
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v(_vm._s(_vm.trans("backup.generate_backup")))
                ]),
                _vm._v(" "),
                _c("show-tip", {
                  attrs: { module: "backup", tip: "tip_backup" }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("switches", {
                      staticClass: "m-l-20",
                      attrs: { theme: "bootstrap", color: "success" },
                      model: {
                        value: _vm.backupForm.delete_previous,
                        callback: function($$v) {
                          _vm.$set(_vm.backupForm, "delete_previous", $$v)
                        },
                        expression: "backupForm.delete_previous"
                      }
                    }),
                    _vm._v(
                      " " +
                        _vm._s(_vm.trans("backup.delete_previous_backup?")) +
                        " "
                    ),
                    _c("show-tip", {
                      attrs: {
                        module: "backup",
                        tip: "tip_delete_previous_backup",
                        type: "field"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-info btn-sm",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.createBackup($event)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.trans("backup.generate_backup")))]
                  )
                ])
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _vm.backups
                  ? _c("div", { staticClass: "table-responsive" }, [
                      _c("table", { staticClass: "table" }, [
                        _c("thead", [
                          _c("tr", [
                            _c("th", [
                              _vm._v(_vm._s(_vm.trans("backup.name")))
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(_vm._s(_vm.trans("backup.size")))
                            ]),
                            _vm._v(" "),
                            _c("th", { staticClass: "table-option" }, [
                              _vm._v(_vm._s(_vm.trans("general.action")))
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          _vm._l(_vm.backups, function(backup) {
                            return _c("tr", [
                              _c("td", {
                                domProps: { textContent: _vm._s(backup.name) }
                              }),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(backup.size))]),
                              _vm._v(" "),
                              _c("td", { staticClass: "table-option" }, [
                                _c("div", { staticClass: "btn-group" }, [
                                  _c(
                                    "a",
                                    {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: "Download",
                                          expression: "'Download'"
                                        }
                                      ],
                                      staticClass: "btn btn-success btn-sm",
                                      attrs: {
                                        href: _vm.getDownloadLink(backup)
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fas fa-download"
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      directives: [
                                        {
                                          name: "confirm",
                                          rawName: "v-confirm",
                                          value: {
                                            ok: _vm.confirmDelete(backup)
                                          },
                                          expression:
                                            "{ok: confirmDelete(backup)}"
                                        },
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: "Delete Backup",
                                          expression: "'Delete Backup'"
                                        }
                                      ],
                                      key: backup.name,
                                      staticClass: "btn btn-danger btn-sm"
                                    },
                                    [_c("i", { staticClass: "fas fa-trash" })]
                                  )
                                ])
                              ])
                            ])
                          }),
                          0
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.backups.length
                  ? _c("module-info", {
                      attrs: {
                        module: "backup",
                        title: "module_info_title",
                        description: "module_info_description",
                        icon: "database"
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }